@import '../index.scss';

::placeholder {
	color: $primary;
}

button.primary {
	padding: 1.25rem 2rem;
	color: $bg-light;
	font-size: 1rem;
	font-weight: 600;
	background: $primary;
	border: 0;
	border-radius: 2rem;
	transition: $transition;

	&:hover {
		scale: 1.1;
	}
}

.icon {fill: $primary;}



/////////// HEADER ///////////

header {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 12rem;
	background: $bg-light;
	z-index: 999;

	@media (max-width: $bp-tablet) {padding: 1.5rem 2rem;}

	.title {
		display: flex;
		// align-items: center;
		gap: .7rem;
		color: $primary;
		transition: $transition;
		&:hover {opacity: .7;}

		.logo {height: 2rem;}

		h1 {
			font-size: 1.1rem;
			font-weight: 800;
			line-height: 1;
			@media (max-width: $bp-phone) {display: none;}
		}
	}

	#mobile-nav {
		@extend %button-nobg;
		display: none;
		@media (max-width: $bp-phone) {display: block;}
		svg {
			height: 1.25rem;
			fill: $primary;
		}
	}
	
	nav {
		display: flex;
		align-items: center;
		gap: 2rem;

		@media (max-width: $bp-phone) {
			display: none;
			position: absolute;
			top: 5rem; left: 0;
			flex-flow: column;
			gap: 1.5rem;
			padding: 2rem 2rem 4rem;
			width: 100%;
			overflow: hidden;
			background: $bg-light;

		}

		a {
			color: $primary;
			transition: $transition;
			&:hover:not(.active) {opacity: .6;}
		}
	
		.active {
			font-weight: 700;
			cursor: default;
		}

		input {
			width: 9rem;
			padding: .15rem .5rem;
			border: 0;
			border-bottom: 2px solid $primary;
			background: transparent;
			transition: all .75s ease-in-out;
			@media (max-width: $bp-phone) {width: 100%; padding: .25rem .5rem;}
		}

		.search-toggle {
			width: 0; padding: .15rem 0;
			@media (max-width: $bp-phone) {width: 100%; padding: .25rem .5rem;}
		}

		.search-button {
			@extend %button-nobg;
			svg {
				margin-top: 5px;
				width: .8rem;
				fill: $primary;
			}
			@media (max-width: $bp-phone) {display: none;}
		}

		#theme-button {
			padding: .5rem 1rem;
			color: $bg-light;
			background: $primary;
			border: 0;
			border-radius: 2rem;
		}
	}
}

.toggle {
	&-head {box-shadow: 0 0 0 100vmax rgba(0,0,0,.5);}
	&-nav {display: flex;}
	&-bg {filter: blur(5px); pointer-events: none;}
}


/////////// FOOTER ///////////

footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 2rem;
	padding: 2rem 3rem;
	min-height: 5rem;
	color: $primary;
	background: rgba(82, 43, 238, 0.05);
	border-radius: 1.5rem;
	font-size: 12px;

	@media (max-width: $bp-tablet) {padding: 2.5rem 3rem;}
	@media (max-width: $bp-phone) {
		flex-flow: column; 
		gap: 3rem;
	}

	.contacts {
		display: flex;
		align-items: center;
		gap: 1rem;
		list-style: none;

		a {
			position: relative;
			display: flex; 
			flex-flow: column;
			align-items: center;

			&:hover {
				svg {
					opacity: 0.6;
				}

				.tooltip {
					padding: .5rem 1rem;
					height: 2rem;
				}
			}
		}

		svg {
			width: 1.5rem;
			fill: $primary;
			transition: $transition;
		}

		.tooltip {
			position: absolute;
			bottom: 2rem;
			padding: 0 1rem;
			height: 0;
			background: $bg-light;
			border-radius: 2rem;
			white-space: nowrap;
			overflow: hidden;
			transition: $transition;
		}
	}
}