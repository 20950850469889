@import url("https://use.typekit.net/fas5nxr.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary: #522BEE;
$bg-light: #f9f9ff;
$text: #161616;
$shadow: drop-shadow(0 .5rem .5rem rgba(82, 43, 238, 0.05));
$transition: all .4s ease-in-out;

$bp-tablet: 1023px;
$bp-phone: 767px;

%tags {
    display: flex;
	flex-flow: row wrap;
	gap: .5rem;
	list-style: none;
	margin: .5rem -.15rem 0; padding: 0;

	li {
		padding: .25rem .5rem;
		text-transform: uppercase;
		font-size: .7rem;
		line-height: 1;
		border: 1px solid;
		border-radius: 2rem;
		&::before {content: "# "; font-weight: 300;}
	}
}

%button-nobg {
	padding: .25rem .5rem;
	color: $primary;
	background: none;
	border: 0;
	transition: $transition;
	&:hover {opacity: .7;}
}

* {
	margin: 0; padding: 0;
	scroll-behavior: smooth;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'poppins', -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $text;
	background: $bg-light;
	user-select: none;
}

a:visited {color: $primary;}