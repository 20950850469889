@import '../../index.scss';

.play {
    padding: 2rem 10vw 10rem;
	@media (max-width: $bp-tablet) {padding: 2rem 3rem 10rem;}
	@media (max-width: $bp-phone) {padding: 2rem 1rem 10rem;}

    display: flex;
    flex-flow: row wrap;
    gap: 1rem;

    .play-thumb {
        flex-grow: 1;
        height: 15rem;
        border-radius: 1rem;
        overflow: hidden;
        @media (max-width: $bp-phone) {height: auto;}

        img {
            object-fit: cover;
            height: 100%; width: 100%;
            transition: $transition;
            &:hover {scale: 1.1;}
        }
    }
}