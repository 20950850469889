@import '../../index.scss';

.project {
    img {pointer-events: none;}

    margin: 2rem 15vw 10rem;
    @media (max-width: $bp-tablet) {margin: 0 5vw 10rem;}
    @media (max-width: $bp-phone) {margin: 0 1.5rem 10rem;}
}

.project-brief {
    h1 {
        margin: 5rem 0 2rem;
        color: $primary;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;

        @media (max-width: $bp-phone) {font-size: 2.5rem;}
    }

    h3 {
        font-size: .7rem;
        font-weight: 300;
        line-height: 1.75;
        text-transform: uppercase;
    }

    h4 {
        margin-right: 2rem;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.75;
    }

    .info {
        display: grid;
        grid-template: 4rem auto / 3fr 1fr 1fr;
        row-gap: 2rem; 
        column-gap: 1rem;

        @media (max-width: $bp-tablet) {
            grid-template: auto / 1fr 1fr;

            .overview {grid-column: span 2;}
            .date {grid-area: 3 / 2;}
            .tags {grid-column: span 2;}
        }

        .overview {grid-row: span 2;}
        .taglist {@extend %tags;}
    }
}

.project-main {
    margin: 5rem 0;
    @media (max-width: $bp-phone) {margin: 3rem 0;}

    section {
        line-height: 1.75;
    }

    h2 {
        margin: 5rem 0 2rem;
        color: $primary;
        font-size: 2rem;
    }

    h3 {
        margin: 3rem 0 1rem;
        font-size: 1.5rem;
        font-weight: 600;
    }

    p {
        margin: 1rem 0;
        padding: 2rem 3rem;
        color: #5f5f5f;
        border: 1px solid rgba(82, 43, 238, 0.1);
        border-radius: 1.5rem;
    }

    img {
        width: 100%;
        border-radius: 1.5rem;
    }

    .fullscreen {
        width: 100vw;
        margin: 2rem -15vw;
        border-radius: 0;

        @media (max-width: $bp-tablet) {margin: 2rem -5vw;}
        @media (max-width: $bp-phone) {margin: 2rem -1.5rem;}
    }

    ul {list-style-type: none;}

    div.cols {display: flex; @media (max-width: $bp-phone) {flex-flow: row;}}

    .cols {
        display: flex;
        flex-flow: row nowrap;
        gap: .5rem;
        margin: .5rem 0;
        @media (max-width: $bp-phone) {flex-flow: column;}

        div {flex: 1 1 0;}

        img {
            width: 100%; height: 100%; 
            object-fit: cover;
        }
    }

    .col4 {
        li {
            width: 25%;
            @media (max-width: $bp-phone) {width: 100%;}
        }
    }

    .small {
        width: 40vw;
        margin: 0 auto;
        @media (max-width: $bp-tablet) {width: 100%;}
    }
}

.navigate {
    margin: 7rem 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: $bp-phone) {margin: 7rem 0 0;}

    a {
        padding: .5rem 1rem;
        border: 1px solid;
        border-radius: 2rem;
        transition: $transition;

        &:hover {
            color: $bg-light;
            background: $primary;
        }
    }
}