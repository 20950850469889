@import '../../index.scss';

.about {
    padding: 2rem 35vw 10rem;
	@media (max-width: $bp-tablet) {padding: 2rem 25vw 10rem;}
	@media (max-width: $bp-phone) {padding: 2rem 1rem 10rem;}
    // @media (max-width: $bp-tablet) {padding: 2rem;}

    h2 {
        margin: 3rem 0 1rem;
        color: $primary;
    }

    h3 {
        font-size: 1.1rem; 
        font-weight: 600;
    }

    .profile {
        display: flex; justify-content: center; align-items: center;
        width: 100%; height: 200px;
        border-radius: 2rem;
        overflow: hidden;

        img {
            width:100%; height: 100%;
            object-fit: cover;
        }

        #icon2 {display: none;}

        &:hover {
            #icon2 {display: block;}
            #icon1 {display: none;}
        }
    }

    .intro {
        margin: 2rem;
        font-size: 18px;
        line-height: 1.7;
    }

    .cols {
        display: grid;
        grid-template: auto / 1fr 1fr;
        column-gap: .5rem;

        div p {
            margin: .5rem 1rem; 
            font-size: 14px;
        }

        img {
            width: 100%;
            border-radius: 1.5rem;
        }
    }

    .contact {
        div {
            display: flex;
            gap: 1rem;
        }

        a {
            display: flex; 
            justify-content: center; align-items: center;
            width: 4rem; height: 4rem;
            // background: $primary;
            border: 1px solid;
            border-radius: 100%;
            transition: $transition;

            svg {
                fill: $primary; 
                width: 1.5rem;
                transition: all .75s ease-in-out;
            }

            &:hover {
                background: $primary;
                transform: scale(1.2);
                svg {fill: $bg-light;}
            }
        }
    }
}