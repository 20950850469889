@import '../../index.scss';

.error {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 15rem;
    height: calc(100vh - 30rem);
    @media (max-width: $bp-phone) {
        margin: 2rem 3rem;
        height: 50vh;
    }

    h1 {
        font-size: 5rem;
        font-weight: 800;
        color: $primary;
    }

    p {
        font-size: 1.25rem;
        font-weight: 500;
        margin-left: .4rem;
    }

    button {align-self: start;}
}